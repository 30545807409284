.Footer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;

  &-wrapper {
    background-color: #222222;
    height: auto;
    width: calc(80% - 60px);
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 80px 0px 0px #222222;
    display: flex;
    align-items: center;
    padding: 30px 30px;
    gap: 30px;
  }

  &-lang {
    display: none;
    color: white;
    font-size: 16px;
    gap: 8px;
    height: 16px;
    line-height: 16px;

    span {
      position: relative;
      top: 5px;
      opacity: .5;
      cursor: pointer;
      transition: opacity .25s ease;

      &.active {
        cursor: default;
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &-icons {
    flex: 1;
    display: flex;
    gap: 16px;

    &--button {
      position: relative;
      display: flex;
      align-items: center;
      width: auto;
      height: 60px;
      white-space: nowrap;
      gap: 8px;
      padding: 0 20px;
      border-radius: 60px;
      background-color: hsl(0, 0%, 21%);
      overflow: hidden;
      cursor: pointer;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);

      p {
        position: relative;
        color: #ffffff;
        font-size: 15px;
        line-height: 15px;
        z-index: 2;
        cursor: pointer;
      }

      img {
        width: 26px;
        fill: white;
        z-index: 2;
        cursor: pointer;
      }

      &:before {
        content: '';
        transition: all 1s ease;
        position: absolute;
        width: 100%;
        height: 200%;
        top: -50%;
        left: 0;
        opacity: 0;
        cursor: pointer;

        filter: saturate(2) blur(5px);
        background-image: conic-gradient(from 180deg, 
          #ffffff 14%,
          #f27965 28%,
          #d35fc6 42%,
          #8784da 56%,
          #74bcdb 70%,
          #5071da 84%,
          #e246af 98%
        );
      }

      &:hover {
        &:before {
          transform: scale(2) rotate(180deg);
          opacity: .6;
        }
      }
    }

    &--round {
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      background-color: hsl(0, 0%, 21%);
      overflow: hidden;
      cursor: pointer;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);

      svg {
        position: absolute;
        width: 26px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: white;
      }

      &:before {
        content: '';
        transition: all 1s ease;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;

        filter: saturate(2) blur(5px);
        background-image: conic-gradient(from 180deg, 
          #ffffff 14%,
          #f27965 28%,
          #d35fc6 42%,
          #8784da 56%,
          #74bcdb 70%,
          #5071da 84%,
          #e246af 98%
        );
      }

      &:hover {
        &:before {
          transform: scale(2) rotate(180deg);
          opacity: .6;
        }
      }
    }
  }

  &-copyright {
    position: relative;
    font-size: 15px;
    line-height: 20px;
    color: white;
    opacity: .25;
    top: 4px;
  }

  @media screen and (max-width: 640px) {
    .Footer-wrapper {
      flex-direction: column;
      gap: 32px;
    }

    .Footer-lang {
      display: flex;
    }
  }
}
