.Name {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  transition: opacity 1s ease;
  transition-delay: .5s;

  &.enter {
    opacity: 0;
  }

  p {
    font-family: 'GothamBold';
    position: relative;
    font-size: 13vw;
    text-align: center;
    font-weight: bold;
    color: white;
    padding: 0;
    margin: 0;
  }
}
