.Scene {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 1s ease, top 1s ease;

  &.enter {
    top: -20%;
    opacity: 0;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(1);
    transform-origin: top;

    @media screen and (max-width: 640px) {
      transform: translateX(-50%) scale(1) !important;
    }
  }
}