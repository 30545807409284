.About {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  &.enter {

    .About-lang{
      opacity: 0;
    }
  }

  &-lang {
    transition: opacity 1s ease;
    transition-delay: .6s;
    opacity: 1;
    position: fixed;
    color: white;
    top: 50px;
    right: 30px;
    font-size: 16px;
    display: flex;
    gap: 8px;
    height: 16px;
    line-height: 16px;

    span {
      position: relative;
      top: 5px;
      opacity: .5;
      cursor: pointer;
      transition: opacity .25s ease;
      outline: none;

      &.active {
        cursor: default;
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    margin: 150px 10% 60px 10%; 
    flex: 1;

    &::before {
      content: '';
      display: none;
      position: absolute;
      background-color: black;
      transform: translateX(-10%);
      width: 100vw;
      height: calc(100% + 100px);
      top: 100px;
      left: 0;
      opacity: .75;
      z-index: 1;
    }
  
    &::after {
      content: '';
      display: none;
      position: absolute;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      transform: translateX(-10%);
      width: 100vw;
      height: 100px;
      top: 0;
      left: 0;
      opacity: .75;
      z-index: 1;
    }
  }

  &-hello {
    font-weight: 700;
    font-size: 45px;
    line-height: 55px;
    padding-bottom: 32px;
    position: relative;
    letter-spacing: 1px;
    text-align: center;
    color: white;
    margin: 0;
    z-index: 2;
  }

  &-texte {
    font-size: 25px;
    line-height: 43px;
    position: relative;
    letter-spacing: 1px;
    text-align: center;
    color: white;
    margin: 0;
    z-index: 2;

    a {
      position: relative;
      font-weight: 700;
      color: #FFFFFF;
      text-decoration: none;

      &::before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: white;
        transition: width .25s ease;
      }

      &:hover {
        &::before {
          width: 0%;
        }
      }
    }
  }

  &-Wordslider {
    position: relative;
    display: inline-block;
    line-height: 0px;
    width: 100px;
    font-weight: 700;
    top: -25px;
    cursor: pointer;
    transition: opacity .25s ease;
    outline: none;

    &:hover {
      opacity: .5;
    }
  }

  &-Words {
    position: absolute;
    left: 0;
    opacity: 0;
    transform: translateY(30px);
    transition: all .5s ease;
    width: auto;
    height: 30px;
    line-height: 30px;
    background: #FFFFFF;
    background: linear-gradient(45deg, #8784DA 0%, #74BCDB 25%, #5071DA 50%, #E246AF 75%, #FF2E51 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &.even {
      background: #FFFFFF;
      background: linear-gradient(-45deg, #8784DA 0%, #74BCDB 25%, #5071DA 50%, #E246AF 75%, #FF2E51 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    &.active {
      transition: opacity .5s ease;
      transform: translateY(0px);
      opacity: 1;
    }
  }



  @media screen and (max-width: 640px) {

    .About-wrapper {
      margin: 0 10% 60px 10%;

      &::before,
      &::after {
        display: block;
      }
    }

    .About-texte {
      font-size: 20px;
      line-height: 30px;
    }

    .About-lang {
      display: none;
    }

    .About-Wordslider {
      position: relative;
      display: block;
      margin: 26px auto;
      line-height: 0px;
      width: 100%;
      text-align: center;

      span {
        text-align: center;
        width: 100%;
      }
    }
  }
}
